import React from 'react'
import Helmet from 'react-helmet'

import Layout from '../../components/layout'
import HeaderGeneric from '../../components/HeaderGeneric'

class Generic extends React.Component {
  render() {

    return (
      <Layout>
        <Helmet title="श्री गुरूगजाननलीला ग्रंथ - अध्याय ७ वा" />
        <HeaderGeneric title="श्री गुरूगजाननलीला ग्रंथ" adhyaay='अध्याय ७ वा' num='07'/>
        <div id="main">
          <section id="content" className="main">
            <p>
            श्री गणेशाय नम: । हे गणेशा गजमुखा । तुच हरतो दुःखा । ठेवतो भक्तांस सुखा ॥ १ ॥
तू गणांचा ईश । देवदेवतांचा ईश । तुझे लाभता कृपाशिष । लाभे सर्व देव देवतांचे आशिष ॥ २ ॥
तू पुढारी सर्वांचा असणार । कोण तुला विसरणार ? । जो तुला विसरणार । त्याची दाणादाण होणार ॥ ३ ॥
जो तुला जवळ करणार । त्याचे तू विघ्न हरणार । त्याच्यावर कृपादृष्टी करणार । सर्वतोपरी सहाय्य करणार ॥ ४ ॥
उगीच का म्हटले विघ्नहर्ता ! । सुखकर्ता ! कृपावंता ! । पावतोस आपल्या भक्ता । विनम्र भावे भक्ती करता ॥ ५ ॥
नुसत्या कृपादृष्टीने फायदा । होतो भक्तांस कित्येकदा । तुझ्या सहवासात किती फायदा ! । घडू दे सहवास सदा ॥ ६ ॥
तुझे गणित सोडवणार कोण ? । तू तर विद्येचा जनक । तुझे मुळातच अगाध ज्ञान । तुझ्याशी पैज लावणार कोण ? ॥ ७ ॥
तुला कमी नाही झाल्यात यातना । जी तुझ्या मुखावरून येते कल्पना । जरी तू भोगणार यातना । तरी सुखी ठेवतोस सर्वांना ॥ ८ ॥
उगाच का म्हटले तुज सुखदायका । हे अष्ट विनायका ! । हे सिद्धिविनायका ! । गणामहाराजाची प्रार्थना ऐका ॥ ९ ॥
भक्तांसाठी सहाय्य करावे । कृपाशिष लाभावे । ग्रंथ लेखन निर्विघ्न व्हावे । भक्तां सदा तोषावे ॥ १० ॥
जैसा गणपती पुढारी देवांत । तैसा खंडू पाटिल शेगावात । गावचा कारभार सांभाळण्यात । होता निपुण त्यात ॥ ११ ॥
पाटिल मुळचे धार्मिक संस्कारी । मारूतीचा उत्सव साजरा करी । त्यात गोमाजीची कृपा भारी । ह्या पाटिल घराण्यावरी ॥ १२ ॥
गोमाजींची कर्मभूमी नागझरी । जेथे निर्माण गंगा गोदा लहरी । धन्य ती नागझरी । संत जेथे वास करी ॥ १३ ॥
ज्या घरावर संतकृपा असणार । तो प्रपंच आनंदी होणार । संतच भक्तीत रमवणार । भक्तीमुळेच आनंद मिळणार ॥ १४ ॥
पाटिल घराण्याचा मूळपुरुष महादजी । जो कधी न करे कुणाची जी जी । तो करे एकाचीच जी जी । जेणे सदैव त्याची मर्जी ॥ १५ ॥
तो जी जी करे गुरूंची । ठेवे जाणीव त्यांच्या कृपेची । जेणे सोबत भक्तीमार्गाची । जेणे सोबत सत्संगतीची ॥ १६ ॥
ऐसे घडवले संस्कार पिढ्यांवर । पिढ्या नि पिढ्या जपण्यात तत्पर । संस्कारांस महत्व फार । पाटिलांनी दाखवले वारंवार ॥ १७ ॥
अपत्यांवर ऐसे संस्कार घडवावे । जेणे दुसर्‍यांस आनंद लाभावे । चित्तास शांती समाधान लाभावे । देहाचे सार्थक करावे ॥ १८ ॥
जीव कुठेतरी रमवावा लागतो । अन्यथा कंटाळा येतो । चांगल्या ठिकाणी रमल्यास उत्साह येतो । जीवास आनंद लाभतो ॥ १९ ॥
चांगले वाईट नाही शिकवावे लागत । आपणास कळते नकळत । आत्म्याच्या आवाजाकडे दुर्लक्ष करण्यात । यातना भोगाव्या लागतात ॥ २० ॥
नको यातना जीवास । नको नको पीडा त्यास । जाणावे अंतर्मनास । आणावे आचरणास ॥ २१ ॥
जे अगदी सोपे असते । ते दुर्लक्ष करण्याते । अवघड होते । भलतीकडेच चित्त भुलते ॥ २२ ॥
भुलावे कुणाला ? । भुलावे ईश्वरी सत्तेला । नका भुलू मृगजळाला । तेणे घातच आपला ॥ २३ ॥
शक्तीचा दुरूपयोग करू नये । दुसर्‍यास पीडा देऊ नये । आत्म्यास क्लेश देऊ नये । दुसर्‍याचा आत्मा दुखवू नये ॥ २४ ॥
जीवनात आत्म्याला महत्व फार । हेच सांगते वेदांचे सार । नको नुसता देहावर भार । ठेवा सत्वृत्तींवर भार ॥ २५ ॥
पाटिल घराणे शक्तीचे प्रतीक । मल्ल होता त्यांच्यात एक एक । पाटिल घराणे भक्तीचे प्रतीक । धार्मिक भाव जागृत एक एक ॥ २६ ॥
कुकाजी कडताजी बंधु दोघे । त्यांच्या हाकेकडे गाव बघे । गावाचे हीत बघती दोघे । केवळ स्वतःचेच न बघे ॥ २७ ॥
भाग्य प्रत्येकाचे वेगळे । कुकाजीस नव्हती पोरे बाळे । कडताजीस सहा मुले । सारा प्रपंच आनंदात चाले ॥ २८ ॥
खंडू पाटिलास मुख्य म्हणती । पाच बंधू त्याच्या सोबती । नारायण, हरी, गणपती । कृष्णाजी नि मारूती ॥ २९ ॥
धन्य त्या पाटिल वंशाची । कृपादृष्टी लाभली गुरूंची । सारी किमया पूर्व पुण्याईची । आणि त्यात नव्या भरीची ॥ ३० ॥
गुरूविणा काही नाही । सांडण्यात क्लेश राही । जेथे गुरूकृपा राही । आनंद साम्राज्य राही ॥ ३१ ॥
समर्थ मुक्काम मंदिरात । मारूतीरायाच्या सान्निध्यात । शिष्य भास्कर होता सेवेत । महाराजांच्या कृपाछत्रात ॥ ३२ ॥
जेथे मारूती असणार । तेथे रामराया असणार । जो स्वतःस विसरणार । तोच दुसर्‍यास देणार ॥ ३३ ॥
एकाच्या स्थापनेत । दुसर्‍याची स्थापना । भक्तीनेच साकार कल्पना । मनाच्या जाती वल्गना ॥ ३४ ॥
कृतीनेच सर्व मिळते । हे सत्य अनुभवास येते । जीवास कर्म करावे लागते । कर्मावाचून न जगणे होते ॥ ३५ ॥
जे सत्य असणार । ते असत्य कैसे होणार ? । जे जगास उद्धरणार । त्यांचे उत्सव होणार ॥ ३६ ॥
उत्सव करण्याचे प्रयोजन । एकोपा आनंदास स्थान । वैर भावास न मिळे स्थान । परी मनःशांती समाधान ॥ ३७ ॥
पाटिल आडदांड मुळचे । न जाणले मन समर्थांचे । अपशब्द त्यांस बोलायचे । नाना पीडा द्यावयाचे ॥ ३८ ॥
नाना पीडा समर्थांस । पहावेना भास्करास । तो वदला समर्थांस । जाऊ आपण दुसर्‍या स्थानास । ३९ ॥
महाराज वदले भास्करास । पीडा होते ह्या देहास । त्याची जाणीव न आम्हास । जेणे क्लेश न आम्हास ॥ ४० ॥
जो “मी” विसरणार । त्यास पीडा काय होणार ? । सुखदुःख सारखेच वाटणार । प्रारब्ध आनंदे भोगणार ॥ ४१ ॥
भास्करा! जाण तत्व जरा । हा सारा परमेश्वराचा पसारा । व्याधी उपाधीसही मिळे थारा । कुणी न सुटणार बिचारा ॥ ४२ ॥
जैसा ज्याचा संबंध साठा । तैसा परमार्थी वाटा । पाटिल गुरूसेवेत सर्वथा । जेणे हाती गावची सत्ता ॥ ४३ ॥
लक्ष गावातल्या लोकांवरती । साधू भोंदू नजर न टाके स्त्रियांवरी । कानी येता तक्रारी । शिक्षा त्यास भारी होई ॥ ४४ ॥
पाटिल गावचे पुढारी । गावावर दरारा भारी । शुद्ध हेतू त्यांच्या अंतरी । स्त्रियांची अब्रू जतन करी ॥ ४५ ॥
हे जाणले समर्थांनी । दडलेला हेतू पाटिलांच्या मनी । अंधश्रद्धा नव्हती मनी । सदा पहाती पडताळुनी ॥ ४६ ॥
महाराज वदती अरे भास्कर ! । उताविळ नको दम धर । ठेव विश्वास माझ्यावर । पाटिलच चालवेल कारभार ॥ ४७ ॥
हे ओळखले समर्थांनी । कारभार केला पाटिलांनी । पिढ्या नि पिढ्या खपुनी । जीव रमवला सेवेतुनी ॥ ४८ ॥
सत्य जाणण्यास । योग्य प्रतीचा लागे माणुस । शुद्ध अंतःकरण ज्यास । सत्य कळते त्यास ॥ ४९ ॥
खंडू पाटिल हुशार भला । चापती नजर ठेवू लागला । समर्थांच्या हालचाली टिपू लागला । परिक्षा घेण्यास न थकला ॥ ५० ॥
पाटिल परिक्षा घेणार । समर्थ परिक्षा देणार । प्रारब्ध भोग कुणास न टळणार । जग रहाटी अशीच चालणार ॥ ५१ ॥
जग शहाणे प्रसंगातून । सारे योगायोगातून । एक एक प्रसंगातून शिकवण । हेच समर्थांचे समर्थपण ॥ ५२ ॥
मुद्दाम न कधी सुयोगाचे । सारे सहजपणाचे । परिक्षार्थी नि परिक्षक असावे योग्यतेचे । तेणे महत्व टिकते परिक्षेचे ॥ ५३ ॥
हरी पाटिल पहेलवान । खंडू पाटिलाचा बंधू जाण । मुळातच आडदांडपण । त्यात बरोबर पाटिलपण ॥ ५४ ॥
एके दिवशी पाटिल हरी । महाराजांस चिथवू लागला भारी । महाराज दुर्लक्ष करी । सहनशीलता अंगी भारी ॥ ५५ ॥
हरीने त्यांस केले प्रवृत्त । कुस्तीत करावे मला चीत । कर तू मला पराजीत । नाही तर होशील फजीत ॥ ५६ ॥
समर्थ वदले हरीप्रत । नको पडू भानगडीत । तुम्ही पेहेलवान सशक्त । परी नाही योगबळात ॥ ५७ ॥
पैज लावू नकोस माझ्याशी । पैज लाव पेहेलवानाशी । तोडीचा असावा लागतो त्यासी । अन्यथा अनर्थ प्रसंगासी ॥ ५८ ॥
हरीने न जाणले योग्यतेला । समर्थांस कमी लेखू लागला । अहंकार कारणीभूत झाला । मनोमनी संतोषला ॥ ५९ ॥
जगात नाही टिकत अहंकार । अहंकारच घात करणार । कुणी न त्यात तरणार । हेच अनुभवास येणार ॥ ६० ॥
हरीने हट्ट सोडला नाही । अहंकारास जाणले नाही । समर्थांस जाणले नाही । कुस्ती वाचून राहिला नाही ॥ ६१ ॥
कुस्तीची तयारी झाली । तालिमीत जमली प्रेक्षक मंडळी । आजची कुस्ती रंगली । कधी न ऐसी झालेली ॥ ६२ ॥
कुस्ती ऐन रंगात आली । हरीची काया घामाघूम झाली । समर्थ न हलले मुळी । हरीची शक्ती कमी पडली ॥ ६३ ॥
खाली बसले गजानन । म्हणे उठव आता मजलागून । जरी असशील पहेलवान । मी न हलणार येथून ॥ ६४ ॥
हरीने नाना प्रयत्न केले । समर्थ न मुळीच हलले । सर्व डाव करून पाहिले । सर्व प्रयत्न व्यर्थ ठरले ॥ ६५ ॥
हरी म्हणे मनात । कैसे करावे यास चीत ? । तशीच चर्चा प्रेक्षकात । ऐसा पहेलवान न दृष्टीप्रत ॥ ६६ ॥
हा नाही पहेलवान । अंगी योगसामर्थ्य जाण । हरीने न जाणता साधन । फजीतीस होईल कारण ॥ ६७ ॥
प्रेक्षकात लागते पैज । कोण जिंकतो आज । जो तो उत्सुक आज । रंगला डाव आज ॥ ६८ ॥
हरी हरला पैज । समर्थे जिंकली पैज । हरीस कळले आज । तोडीचा मिळाला आज ॥ ६९ ॥
प्रेक्षक सारे स्तब्ध झाले । योगबळ सार्‍यांस पटले । हरीचे तोंड पडले । समर्थ काही न बोलले ॥ ७० ॥
हरीने जाणले समर्थांस । शरण गेला त्यांस । शिकवावे तुम्ही आम्हांस । जे जे येते आपणांस ॥ ७१ ॥
हरीने सामर्थ्य जाणले । प्रत्यक्ष अनुभवले । त्रास देणे सोडले । मनोमन नक्की केले ॥ ७२ ॥
हरीने सांगितले बंधुंस । जाऊ नका त्यांच्या वाटेस । व्हाल कारण फजितीस । मी जाणले योगबळास ॥ ७३ ॥
परी बंधू कैसे ऐकणार ? । अनुभवाने शहाणे होणार । हाच ज्यांचा मनोधर्म असणार । ते अनुभवावीण न स्वस्थ बसणार ॥ ७४ ॥
एक अनुभव घेणार । दुसर्‍यास पीडा होणार । नियतीचे चक्र चालणार । ते कोण थांबवणार ? ॥ ७५ ॥
योगी माणसाची लक्षणं । पहावी सोशिकपणावरून । परिक्षा घेतात सामान्य जन । वारंवार पीडा देऊन ॥ ७६ ॥
समर्थ न कधी सांगे कुणास । योगलीला अवगत आपणास । वाचेने प्रदर्शन करण्यास । माहित नव्हते त्यांस ॥ ७७ ॥
समर्थ न सांगितला जातो । समर्थ ओळखला जातो । तो कृतीनेच समर्थ ठरतो । योगाचे महत्व पटवून देतो ॥ ७८ ॥
जैशी पत्नी पतीची काळजी घेणार । तैसा भगवंत भक्ताची काळजी घेणार । ज्याच्यावर जीव जडणार । तेथे ऐसेच होणार ॥ ७९ ॥
एकाने मर्जी संपादायची दुसर्‍याची । दुसरा काळजी वाहातो त्याची । भक्ताने विचारपूस करायची गुरूंची । गुरू काळजी दूर करतात भक्ताची ॥ ८० ॥
सारे एकमेकावर अवलंबून असते । जगात एकट्याचे काहीच नसते । हे मनास अनुभवाने पटते । कुणीतरी कुणासाठीतरी जगायचे असते ॥ ८१ ॥
पाटिलांचेही तसेच होते । गावासाठी झटत होते । सारे गाव त्यांच्यावर अवलंबून होते । इशार्‍यावर सारे चालत होते ॥ ८२ ॥
हरी पाटिल कुस्तीत हरला । समर्थ चरणी लीन झाला । त्याचे बंधू बोलले त्याला । कां रे घाबरतो पिशाला ? ॥ ८३ ॥
आपण पाटिल कुमार । आपले शरीर पिळदार । गावची जमेदारी बरोबर । का रे झुकवतो शीर ? ॥ ८४ ॥
हा पिसा आडदांड । फुकटचे खाऊन झाला द्वाड । माजले त्याचे थोतांड । पक्का भोंदू द्वाड ॥ ८५ ॥
लोकांना सावध करावे । पीशाचे थोतांड बंद करावे । त्यास झोडपून काढावे । जेणे गाव सोडावे ॥ ८६ ॥
साधूचा वेष घेऊन । बायाबापड्या भुलवून । आपल्या नादी लावून । जाईल त्यांना घेऊन ॥ ८७ ॥
परिक्षा घेतल्याविणा । कैसे कळणार आपणा ? । त्यास झोडपल्याविणा । खरे खोटे कळेना ॥ ८८ ॥
आपण पाटिल गावचे । हातात दोर सत्तेचे । गावचे रक्षण करायचे । हेच कर्तव्य समजायचे ॥ ८९ ॥
पाटिल बंधू सशक्त फार । सदा क्रोधास वश होणार । दुसर्‍यांवर वर्चस्व करणार । पाटिलकी गाजवणार ॥ ९० ॥
बंधू म्हणाले हरीला । ऊसाच्या मोळ्यांनी झोडपू त्याला । चला जाऊ मंदिराला । हाकलवून देऊ नंग्याला ॥ ९१ ॥
बंधू आले मंदिराला । बरोबर ऊसाच्या मोळ्या । पुसू लागले नंग्याला । अरे नंग्या ! खातोस का ऊसाला ? ॥ ९२ ॥
आम्ही करू उसाचा प्रहार । एकही वळ तुझ्या पाठीवर । न दिसता ऊस देणार । अन्यथा झोडपणार ॥ ९३ ॥
हरीला हे काही पटेना । हरी काही बोलेना । परी न पटले बंधुंना । क्रोध त्यांस आवरेना ॥ ९४ ॥
म्हणती राहू नकोस ह्या स्थानाला । दाखव तुझ्या योग लीला । क्रोध अनावर झाला । वाणी मुष्टीचा उपयोग झाला ॥ ९५ ॥
ऊसाच्या घेतल्या मोळ्या । करू लागले मोकळ्या । एक एक ऊस काढला । पाठीवर झोडपू लागला ॥ ९६ ॥
कुणास हे न बघवले । पाटिलांनी ऊसाने बडवले । महाराज सहन करू लागले । जशास तसे न वागावे घडले ॥ ९७ ॥
पहाणारा घाबरून पळू लागला । सोशिकपणाचा कळस झाला । पाठीवर एकही वळ न उठला । जो तो आश्चर्यचकित झाला ॥ ९८ ॥ 
पाटिल बंधू थकले । समर्थांस शरण आले । पश्चाताप व्यक्त केले । समर्थ कळस शिखर बनले ॥ ९९ ॥
जैसे होते शिखर । तैसीच होती पायात भर । जे अंतरंगात असणार । तेच प्रकट होणार ॥ १०० ॥
पाटिलांचा मनोधर्म । महाराजांचा मनोधर्म । दोघांचे होते भिन्न कर्म । सर्वांस कळले त्याचे मर्म ॥ १०१ ॥
समर्थ न काही बोलले । हातातले ऊस घेतले । भराभर पिळून पाटिलांस दिले । योग सामर्थ्य दावले ॥ १०२ ॥
समर्थांवर प्रसंग येत होते । नित्य नविन घडत होते । स्वानुभवे समर्थांचे होत होते । त्यातले काही प्रसंग येतात येथे ॥ १०३ ॥
कुकाजी खंडूचा काका । प्रसंग पडता बाका । ईश्वरास मारे हाका । एके दिवशी विचार केला बोलका ॥ १०४ ॥
काका म्हणाला खंडूस । याचना करावी समर्थांस । लाभावे पुत्र रत्नास । कुलदीपक ठरावा वंशास ॥ १०५ ॥
खंडूचे विचार सुरू झाले । काहीतरी कमी जाणवले । मन व्यथित झाले । पत्नीस मन मोकळे केले ॥ १०६ ॥
पत्नीची संमती घेतली । समर्थ दर्शनाची वाट धरली । नाना विचारांची कडबोळी । अंतरंगात दडलेली ॥ १०७ ॥
खंडू पाटिल हुशार फार । ऐसा शब्द टाकणार । शब्द खाली न पडणार । काम निश्चित होणार ॥ १०८ ॥
समोरचा खुशीत असणार । तेव्हाच तो ऐकणार । ऐसे मनावर ठसवणार । काम पूर्ण होणार ॥ १०९ ॥
प्रसंग ओळखावा लागतो । माणुस सूज्ञ लागतो । योग्य वेळेस शब्द जेथे । उपयोग होतो तेथे ॥ ११० ॥
समर्थ शिष्यांसमवेत । आले असता रंगात । विचार खंडूच्या मनात । शब्द टाकावा समर्थांप्रत ॥ १११ ॥
खंडू समर्थांस खुलवू लागला । नाना विषय काढू लागला । समर्थे जाणले खंडूला । काही तरी विचार भला ॥ ११२ ॥
बोलता बोलता म्हणाला । श्री गुरू ! एक विचार मनाला ॥ भारी सतावे मजला । कैसे सांगू तुम्हाला ? ॥ ११३ ॥
तुम्ही सर्व जाणणार । काय यातना असणार । मनोवांच्छा जी असणार । ती पूर्ण कधी होणार ? ॥ ११४ ॥
माझ्यावर कृपा करा । पाटिल वंशवृद्धी करा । ह्याच विचाराची येरझारा । सतावते माझ्या मना ॥ ११५ ॥
महाराज वदले । तू एवढा मोठा । शेती वाडी गायी गोठा । नाही आनंदा तोटा । याचना का संतती करता ? ॥ ११६ ॥
जो वैभव देणार । तोच संतती देणार । मी नंगा गावात फिरणार । मी काय देणार ? ॥ ११७ ॥
खंडूस काही सुचेना । महाराजां सोडवेना । पुन्हा पुन्हा करी याचना । नाही तुमच्या कृपेविणा ॥ ११८ ॥
विचार मनात आला । आपण समर्थांचा हात धरला । तेच देती वैभवाला । उगाच का विनवावे दुसर्‍याला ? ॥ ११९ ॥
गुरूमुळेच या वैभवाला । हा विचार मनोमन पटला । पुन्हा पुन्हा विनवू लागला । चरणावर माथा ठेवला ॥ १२० ॥
त्याच्या मनाच्या तळमळीला । आज अंत आला । तो गदगदला । समर्थांस बिलगला ॥ १२१ ॥
त्याने आळवले समर्थांना । दयाळू अंतःकरणी समर्थांना । कृपा केल्याविणा रहावेना । फळास आल्या त्याच्या विनवण्या ॥ १२२ ॥
समर्थ वदले खंडूस । भीक मागतो सच्चिदानंदास । तोच मनोरथास । पूर्ण करेल हमखास ॥ १२३ ॥
याचना म्हणजे भीक । तू मागितले बालक । म्हणे ठरो कुलदीपक । हीच विनवणी एक ॥ १२४ ॥
भिक्या नाव ठेवशील ? । आमरसाचे भोजन घालशील ? । तुज पुत्र संतती होईल । मनोरथ पूर्ण होईल ॥ १२५ ॥
तू वैभव संपन्न । प्राप्त होता पुत्ररत्न । घालावे ब्राह्मण भोजन । संतोषावे दक्षिणा देऊन ॥ १२६ ॥
खंडूस  जवळ करून । दिले आशिर्वचन । आले त्यास गहिवरून । शब्द न ये मुखातून ॥ १२७ ॥
एकदा वंदन करून । गुरूंची आज्ञा घेऊन । घराकडे निघाला तेथून । हर्षभरीत मनी होऊन ॥ १२८ ॥
वडील मंडळीस सांगितले । पत्नीसही सांगितले । जे जे समर्थ वदले । सर्व आनंदित झाले ॥ १२९ ॥
काही दिवस लोटता । गंगाबाई कांता । हर्षभरीत झाली चित्ता । आशिर्वाद फळास येता ॥ १३० ॥
काही दिवस लोटल्यावर । कांता झाली गरोदर । आनंदास न पारावार । हर्षभरीत झाले घर ॥ १३१ ॥
पूर्ण होता नवसास । पुत्ररत्न कांतेस । याचना आली फळास । आनंद झाला पाटिलास ॥ १३२ ॥
खंडूस दिसले समर्थ । म्हणे पूर्ण केले मनोरथ । तूच खरा कृपावंत । जेणे पुत्र संतती प्राप्त ॥ १३३ ॥
समर्थांचे बोल आठवले । नवस फेडणे नक्की केले । भिकाजी नाव ठेवले । थाटात बारसे केले ॥ १३४ ॥
कुकाजीच्या हर्षाला न पारावार । धर्म केला अपार । संतोषले गरीब थोर । धन धान्य वाटले अपार ॥ १३५ ॥
आमरसाचे भोजन । गावकर्‍यांना बोलावून । संतोषले ब्राह्मण । भरपूर दक्षिणा देऊन ॥ १३६ ॥
समर्थांचे आशिर्वाद आले फळास । खंडूचे भाग्य आले उदयास । पारावार न राहिला आनंदास । पालवी फुटली वंशवेलास ॥ १३७ ॥
समर्थांनी आशिर्वाद द्यावे । पुत्र रत्न जन्मास यावे । ऐसे गुरू लाभावे । जीवन सार्थक व्हावे ॥ १३८ ॥
धन्य ते माता पिता । गुरूस मनोभावे आळवता । प्रसन्न झाले सद्गुरूनाथा । पूर्ण केले मनोरथा ॥ १३९ ॥



॥ शुभं भवतु ॥
॥ श्री गणपती सरस्वतीर्पणमस्तु ॥
॥ इति श्रीगुरूगजाननलीला ग्रंथस्य सप्तमोऽध्यायः संपूर्णः ॥
</p>
          </section>
        </div>
      </Layout>
    )
  }
}

export default Generic
